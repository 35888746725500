import React, { useEffect } from 'react';

function Sessions() {

    const playVid = ()=>{
        var vid = document.getElementById("sessionvideo");
        vid.play();

        document.getElementById('sesvid').classList.add("played")
    }

    useEffect(() => {
        document.getElementById('logo').classList.remove('border')
    });

    return (
        <div className="sessionsPage">
            <div className="sessionsLayout">
                <div className="left">
                    <h2>THE JOURNEY <br />OF UNSEEN TEARS</h2>
                    <p>
                        Every tear has a hidden story. They are small droplets of fluid, but at the same time complex vials of human emotion produced by one of the most basic and primal expressions of our feelings and thoughts: crying.<br /><br />
                        When you look at a tear, you might think you're looking down at the world from an airplane, at dunes, skyscrapers, or shorelines. The topography of a tear looks like the aerial view of an emotional terrain.<br /><br />
                        Several factors influence what the images look like: whether the tear was air-dried or compressed, the volume of the tear fluid, chemicals the tear interacted with, the settings on the camera and the microscope, and how the photograph is processed and printed.
                    </p>
                </div>
                <div className="right">
                    <div className="video" >
                        <a href="javascript:;" id="sesvid" onClick={playVid}>
                            <video className="video-session" id="sessionvideo" controls>
                                <source src="video.mp4" type="video/mp4"/>
                            </video>
                        </a>
                    </div>

                    <h2>THE <br />PROCESS</h2>

                    <p>
                        A relatively simple process, first you capture the dried tear. After you have the tear you dispense it on to a microscopic slide. After this step it takes some time to crystalize and settle. The structures seen under the microscope and in the images are largely crystallized salt, the circumstances under which the tear dries can lead to radically dissimilar shapes and formations, so two psychic tears with the exact same chemical makeup can look very different up close.
                    </p>

                    <br /><br /><br /><br /><br /><br />
                    
                    <div className="fullImage">
                        <img src="microscope.jpg" alt="session" />
                    </div>

                    <div className="doubleCol">
                        <div className="leftContent">
                            <p>
                                Variables like chemistry, the viscosity, the setting, the evaporation rate, and the settings of the microscope all affect the appearance of a dried tear.<br /><br />Scientifically, tears are divided into three different types, based on their origin. Both tears of grief and joy are psychic tears, triggered by extreme emotions, whether positive or negative. Basal tears are released continuously in tiny quantities (on average, 0.75 to 1.1 grams over a 24-hour period) to keep the cornea lubricated. <br /><br />Reflex tears are secreted in response to an irritant, like dust, onion vapors or tear gas. <br /><br />All tears contain a variety of biological substances (including oils, antibodies, and enzymes) suspended in salt water. Tears from each of the different categories include distinct molecules as well. Emotional tears, for instance, have been found to contain protein-based hormones including the neurotransmitter leucine enkephalin, a natural painkiller that is released when the body is under stress.
                            </p>
                        </div>
                        <div className="rightContent">
                            <img src="tearsMicro.jpg" alt="session" />
                        </div>
                    </div>

                    <h2>THE <br />COLLECTION</h2>

                    <p>
                        The tears of the kids were collected from their handkerchiefs, their sleeves, scarves, and tissue papers. The dried tears came to life under a microscope and were photographed by Maurice Mikkers in his lab.
                    </p>

                    <br /><br />

                    <img src="microscope1.jpg" alt="session" />

                    <br /><br />

                    <div className="doubleCol mgb0">
                        <div className="leftContent">
                            <p>
                                Images taken under a microscope, also known as micrographs, are all about the details. It is a time consuming and precise task, so Mikkers built his own scanning stage and software to control it.
                            </p>
                        </div>
                    </div>

                    <br /><br />

                    <img src="microscope2.jpg" alt="session" />

                    <br /><br />

                    <div className="doubleCol mgb0">
                        <div className="leftContent">
                            <p>
                                This helped him to create ultra-detailed high-resolution images of the tears accurately and “automatically” by taking, on an average, 500 pictures in a comprehensive grid pattern. This enables him to capture the complete tear in its full glory.<br /><br />The process of photographing and processing the data takes around 90 minutes, including the rendering of the photographs into one giant picture. The entire process takes around three hours.<br /><br />This ultimately yields one ultra-detailed image showing a unique tear as a work of art. The process allows Mikkers to use a crystalized teardrop of 1 millimeter and easily print it with a 1-meter diameter, making the total enlargement 1.000x.
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Sessions;