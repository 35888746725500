import './App.css';

import Header from './components/Header'
import Sidebar from './components/Sidebar'
import LogoGenerator from './components/LogoGenerator';
import Stories from './components/Stories';
import Sessions from './components/Sessions';
import Book from './components/Book';
import Storiesdetails from './components/StoriesDetails';
import ErrorPage from './components/ErrorPage';

import { Routes, Route, Navigate, ScrollRestoration } from "react-router-dom";
import LogoContext from "./context/LogoContext";
import { useState } from 'react';

function App() {

  const [logo, setLogo] = useState('/placeholderLogoFit.jpg')
  const [logoClass, setLogoClass] = useState('logo')

  return (
    <LogoContext.Provider value={{ logo, setLogo, logoClass, setLogoClass }} >
      <div className="main">
        <Sidebar />

        <div className="pagecontent">
          <Header />

          <Routes>
            <Route path="/" element={<LogoGenerator />} errorElement={<ErrorPage />} />
            <Route path="stories" element={<Stories />} />
            <Route path="/stories/details/:id?" element={<Storiesdetails />} />
            <Route path="/sessions" element={<Sessions />} />
            <Route path="/book" element={<Book />} />
            <Route path="/404" element={<ErrorPage />} />
            {/* <Route path='*' element={<Navigate to="/404" />} /> */}
          </Routes>


        </div>

      </div >
    </LogoContext.Provider>
  );
}


export default App;
