
import React , { useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Navigation, Pagination } from "swiper";

import 'swiper/css';
import "swiper/css/navigation";
import "swiper/css/pagination";

function Book() {

    useEffect(() => {
        document.getElementById('logo').classList.remove('border')
    });

    return (
        <div className="bookPage">
            <div className="intro">
                <div>
                    THIS&nbsp;&nbsp;IS&nbsp;&nbsp;A&nbsp;&nbsp;BOOK&nbsp;&nbsp;OF&nbsp;&nbsp;THE<br />
                    REAL&nbsp;&nbsp;UNTOLD&nbsp;&nbsp;&nbsp;STORIES&nbsp;&nbsp;OF<br />
                    CHILDREN&nbsp;&nbsp;&nbsp;WHO&nbsp;&nbsp;ARE&nbsp;&nbsp;SEEK-<br />
                    ING&nbsp;&nbsp;&nbsp;A&nbsp;&nbsp;&nbsp;HOME.&nbsp;&nbsp;&nbsp;CHILDREN<br />
                    THAT&nbsp;&nbsp;ARE&nbsp;&nbsp;&nbsp;ABANDONED&nbsp;&nbsp;&nbsp;OR<br />
                    FORGOTTEN.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;CHILDRED<br />
                    WHO&nbsp;&nbsp;HAVE&nbsp;&nbsp;&nbsp;STORIES&nbsp;&nbsp;&nbsp;THAT<br />
                    HAVE&nbsp;&nbsp;MADE&nbsp;&nbsp;THEM&nbsp;&nbsp;CRY.&nbsp;AND<br />
                    IN&nbsp;&nbsp;&nbsp;&nbsp;EVERY&nbsp;&nbsp;&nbsp;TEAR&nbsp;&nbsp;&nbsp;&nbsp;THEY<br />
                    CRY,&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;STORY<br />
                    L&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;E&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;S<br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;WITHIN.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;THIS<br />
                    BOOK&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;IS&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A<br /><br /><br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;REMINDER<br /><br /><br />
                    THAT&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;THEIR<br /><br /><br /><br /><br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;STORIES&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ARE<br /><br /><br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;NOT&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;FORGOTTEN.<br /><br /><br /><br /><br />
                    THAT&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;THEIR<br /><br /><br /><br /><br />
                    TEARS&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ARE<br />
                    NOT<br /><br /><br /><br /><br /><br /><br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;UNSEEN.
                </div>
            </div>

            <div className="bookSlider">

                <img src='bookcover.jpg' className="bookCover" alt="" />

                {/* <Swiper
                    spaceBetween={70}
                    slidesPerView={1}
                    modules={[Navigation,Pagination]}
                    pagination={{type: "fraction"}}
                    navigation={true}
                >
                    <SwiperSlide><img src="slide1.jpg" alt="book" /></SwiperSlide>
                    <SwiperSlide><img src="slide2.jpg" alt="book" /></SwiperSlide>
                    <SwiperSlide><img src="slide3.jpg" alt="book" /></SwiperSlide>
                    <SwiperSlide><img src="slide4.jpg" alt="book" /></SwiperSlide>
                    <SwiperSlide><img src="slide5.jpg" alt="book" /></SwiperSlide>
                    <SwiperSlide><img src="slide6.jpg" alt="book" /></SwiperSlide>
                </Swiper> */}

                <div className="btns">
                    <div className="title">GET THE BOOK</div>
                    <p>REGISTER TO RECIEVE YOUR COPY OF UNSEEN TEARS<br />& BE PART OF THE EXPERIENCE</p>
                    <a href="book.pdf" target="_blank">Download Book</a>
                </div>
            </div>



        </div >

    );
}

export default Book;
