import React, { useState, useEffect, useRef, useContext } from 'react';
import { Link } from "react-router-dom";

import amenaSound from '../audio/AMENA.mp3';
import saleemSound from '../audio/SALEEM.mp3';
import karimSound from '../audio/KARIM.mp3';
// import bilalSound from '../audio/BILAL.mp3';
// import jamalSound from '../audio/JAMAL.mp3';
// import aishaSound from '../audio/AISHA.mp3';



function Stories() {
    useEffect(() => {
        document.getElementById('logo').classList.remove('border')
        document.getElementById('storiesListing').classList.add('reveal')
    });


    const [storyClassNameAmena, setStoryClassNameAmena] = useState('story')
    const [playingAmena, setPlayingAmena] = useState(false);
    const audioRefAmena = useRef(new Audio(amenaSound));

    const [storyClassNameSaleem, setStoryClassNameSaleem] = useState('story')
    const [playingSaleem, setPlayingSaleem] = useState(false);
    const audioRefSaleem = useRef(new Audio(saleemSound));

    const [storyClassNameKarim, setStoryClassNameKarim] = useState('story')
    const [playingKarim, setPlayingKarim] = useState(false);
    const audioRefKarim = useRef(new Audio(karimSound));

    const [storyClassNameBilal, setStoryClassNameBilal] = useState('story')
    const [playingBilal, setPlayingBilal] = useState(false);
    // const audioRefBilal = useRef(new Audio(bilalSound));

    const [storyClassNameJamal, setStoryClassNameJamal] = useState('story')
    const [playingJamal, setPlayingJamal] = useState(false);
    // const audioRefJamal = useRef(new Audio(jamalSound));

    const [storyClassNameAisha, setStoryClassNameAisha] = useState('story')
    const [playingAisha, setPlayingAisha] = useState(false);
    // const audioRefAisha = useRef(new Audio(aishaSound));

    const playAmena = () => {
        setStoryClassNameAmena('story hovered')
        setPlayingAmena(true);
        audioRefAmena.current.play();
    };
    const pauseAmena = () => {
        setStoryClassNameAmena('story')
        setPlayingAmena(false);
        audioRefAmena.current.pause();
        audioRefAmena.current.currentTime = 0;
    };

    const playSaleem = () => {
        setStoryClassNameSaleem('story hovered')
        setPlayingSaleem(true);
        audioRefSaleem.current.play();
    };
    const pauseSaleem = () => {
        setStoryClassNameSaleem('story')
        setPlayingSaleem(false);
        audioRefSaleem.current.pause();
        audioRefSaleem.current.currentTime = 0;
    };

    const playKarim = () => {
        setStoryClassNameKarim('story hovered')
        setPlayingKarim(true);
        audioRefKarim.current.play();
    };
    const pauseKarim = () => {
        setStoryClassNameKarim('story')
        setPlayingKarim(false);
        audioRefKarim.current.pause();
        audioRefKarim.current.currentTime = 0;
    };

    const playBilal = () => {
        setStoryClassNameBilal('story hovered')
        setPlayingBilal(true);
        // audioRefBilal.current.play();
    };
    const pauseBilal = () => {
        setStoryClassNameBilal('story')
        setPlayingBilal(false);
        // audioRefBilal.current.pause();
        // audioRefBilal.current.currentTime = 0;
    };

    const playJamal = () => {
        setStoryClassNameJamal('story hovered')
        setPlayingJamal(true);
        // audioRefJamal.current.play();
    };
    const pauseJamal = () => {
        setStoryClassNameJamal('story')
        setPlayingJamal(false);
        // audioRefJamal.current.pause();
        // audioRefJamal.current.currentTime = 0;
    };

    const playAisha = () => {
        setStoryClassNameAisha('story hovered')
        setPlayingAisha(true);
        // audioRefAisha.current.play();
    };
    const pauseAisha = () => {
        setStoryClassNameAisha('story')
        setPlayingAisha(false);
        // audioRefAisha.current.pause();
        // audioRefAisha.current.currentTime = 0;
    };

    return (
        <div className="storiesPage">
            <div className="storiesListing" id="storiesListing">

                <div className='storyDiv'>
                    <div className={storyClassNameSaleem} onMouseEnter={playSaleem} onMouseLeave={pauseSaleem}>
                        <div className='storyhead'>

                            <Link to="/stories/details/1" onClick={pauseSaleem}>
                                <div className='name'>SALEEM'S<br />TEARS</div>
                            </Link>
                            <div className='age'>Age 8</div>


                        </div>
                        <div className="storybody">
                            <div className="storyline">
                                <span className="storyword" id="storywordA1" style={{ left: '0px' }}>I'm</span>
                                <span className="storyword" id="storywordA2" style={{ left: '35.8906px' }}>eight</span>
                                <span className="storyword" id="storywordA3" style={{ left: '90.375px' }}>years</span>
                                <span className="storyword" id="storywordA4" style={{ left: '144.8594px' }}>old.</span>
                                <span className="storyword" id="storywordA5" style={{ left: '190.0469px' }}>All</span>
                                <span className="storyword" id="storywordA6" style={{ left: '225.938px' }}>I</span>
                                <span className="storyword" id="storywordA7" style={{ left: '243.23438px' }}>really</span>
                                <span className="storyword" id="storywordA8" style={{ left: '306.01558px' }}>want</span>
                            </div>
                            <div className="storyline">
                                <span className="storyword" id="storywordA9" style={{ left: '0px' }}>is</span>
                                <span className="storyword" id="storywordA10" style={{ left: '26.5938px' }}>to</span>
                                <span className="storyword" id="storywordA11" style={{ left: '53.1876px' }}>have</span>
                                <span className="storyword" id="storywordA12" style={{ left: '98.3751px' }}>a</span>
                                <span className="storyword" id="storywordA13" style={{ left: '115.67198px' }}>happy</span>
                                <span className="storyword" id="storywordA14" style={{ left: '170.15638px' }}>family.</span>
                                <span className="storyword" id="storywordA15" style={{ left: '243.21888px' }}>It</span>
                                <span className="storyword" id="storywordA16" style={{ left: '269.81268px' }}>would</span>
                                <span className="storyword" id="storywordA17" style={{ left: '324.29708px' }}>be</span>
                            </div>
                            <div className="storyline">
                                <span className="storyword" id="storywordA18" style={{ left: '0px' }}>so</span>
                                <span className="storyword" id="storywordA19" style={{ left: '26.5938px' }}>nice</span>
                                <span className="storyword" id="storywordA20" style={{ left: '71.7813px' }}>to</span>
                                <span className="storyword" id="storywordA21" style={{ left: '98.3751px' }}>have</span>
                                <span className="storyword" id="storywordA22" style={{ left: '143.5626px' }}>someone</span>
                                <span className="storyword" id="storywordA23" style={{ left: '216.6251px' }}>I</span>
                                <span className="storyword" id="storywordA24" style={{ left: '233.92198px' }}>can</span>
                                <span className="storyword" id="storywordA25" style={{ left: '269.81258px' }}>play</span>
                            </div>
                            <div className="storyline">
                                <span className="storyword" id="storywordA26" style={{ left: '0px' }}>with</span>
                                <span className="storyword" id="storywordA27" style={{ left: '45.1875px' }}>and</span>
                                <span className="storyword" id="storywordA28" style={{ left: '81.0781px' }}>be</span>
                                <span className="storyword" id="storywordA29" style={{ left: '107.6719px' }}>my</span>
                                <span className="storyword" id="storywordA30" style={{ left: '134.266px' }}>best</span>
                                <span className="storyword" id="storywordA31" style={{ left: '179.4532px' }}>friend</span>
                                <span className="storyword" id="storywordA32" style={{ left: '243.2344px' }}>forever.</span>
                                <span className="storyword" id="storywordA33" style={{ left: '325.594px' }}>I</span>
                            </div>
                            <div className="storyline">
                                <span className="storyword" id="storywordA34" style={{ left: '0px' }}>want</span>
                                <span className="storyword" id="storywordA35" style={{ left: '45.1875px' }}>to</span>
                                <span className="storyword" id="storywordA36" style={{ left: '71.7813px' }}>have</span>
                                <span className="storyword" id="storywordA37" style={{ left: '116.9688px' }}>a</span>
                                <span className="storyword" id="storywordA38" style={{ left: '134.26568px' }}>really</span>
                                <span className="storyword" id="storywordA39" style={{ left: '198.04688px' }}>nice</span>
                                <span className="storyword" id="storywordA40" style={{ left: '243.23438px' }}>place</span>
                                <span className="storyword" id="storywordA41" style={{ left: '305.71878px' }}>to</span>
                            </div>
                            <div className="storyline">
                                <span className="storyword" id="storywordA42" style={{ left: '0px' }}>call</span>
                                <span className="storyword" id="storywordA43" style={{ left: '45.1875px' }}>home.</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='storyDiv'>
                    <div className={storyClassNameAmena} onMouseEnter={playAmena} onMouseLeave={pauseAmena}>
                        <div className='storyhead'>
                            <Link to={"/stories/details/2"} onClick={pauseAmena}>
                                <div className='name'>AMEENA'S<br />TEARS</div>
                            </Link>
                            <div className='age'>Age 5</div>
                        </div>
                        <div className="storybody">
                            <div className="storyline">
                                <span className="storyword" id="storywordB1" style={{ left: '0px' }}>Before,</span>
                                <span className="storyword" id="storywordB2" style={{ left: '73.0625px' }}>I</span>
                                <span className="storyword" id="storywordB3" style={{ left: '90.36719px' }}>was</span>
                                <span className="storyword" id="storywordB4" style={{ left: '126.25779px' }}>very</span>
                                <span className="storyword" id="storywordB5" style={{ left: '171.45309px' }}>happy</span>
                                <span className="storyword" id="storywordB6" style={{ left: '225.92969px' }}>with</span>
                                <span className="storyword" id="storywordB7" style={{ left: '268.12499px' }}>my</span>
                                <span className="storyword" id="storywordB8" style={{ left: '291.73439px' }}>mother</span>
                            </div>
                            <div className="storyline">
                                <span className="storyword" id="storywordB9" style={{ left: '0px' }}>and</span>
                                <span className="storyword" id="storywordB10" style={{ left: '35.8906px' }}>my</span>
                                <span className="storyword" id="storywordB11" style={{ left: '62.5px' }}>father</span>
                                <span className="storyword" id="storywordB12" style={{ left: '126.2812px' }}>and</span>
                                <span className="storyword" id="storywordB13" style={{ left: '162.1718px' }}>my</span>
                                <span className="storyword" id="storywordB14" style={{ left: '187.7812px' }}>brother.</span>
                                <span className="storyword" id="storywordB15" style={{ left: '263.1484px' }}>They</span>
                                <span className="storyword" id="storywordB16" style={{ left: '308.3437px' }}>would</span>
                            </div>
                            <div className="storyline">
                                <span className="storyword" id="storywordB17" style={{ left: '0px' }}>play</span>
                                <span className="storyword" id="storywordB18" style={{ left: '45.1953px' }}>with</span>
                                <span className="storyword" id="storywordB19" style={{ left: '90.3906px' }}>me</span>
                                <span className="storyword" id="storywordB20" style={{ left: '118px' }}>every</span>
                                <span className="storyword" id="storywordB21" style={{ left: '170.4766px' }}>day.</span>
                                <span className="storyword" id="storywordB22" style={{ left: '208.6719px' }}>I</span>
                                <span className="storyword" id="storywordB23" style={{ left: '225.97659px' }}>was</span>
                                <span className="storyword" id="storywordB24" style={{ left: '261.86719px' }}>happy</span>
                                <span className="storyword" id="storywordB25" style={{ left: '316.34379px' }}>all</span>
                            </div>
                            <div className="storyline">
                                <span className="storyword" id="storywordB26" style={{ left: '0px' }}>the</span>
                                <span className="storyword" id="storywordB27" style={{ left: '35.8906px' }}>time.</span>
                                <span className="storyword" id="storywordB28" style={{ left: ' 90.3672px' }}>Now</span>
                                <span className="storyword" id="storywordB29" style={{ left: '126.2578px' }}>my</span>
                                <span className="storyword" id="storywordB30" style={{ left: '152.8672px' }}>family</span>
                                <span className="storyword" id="storywordB31" style={{ left: '216.6484px' }}>is</span>
                                <span className="storyword" id="storywordB32" style={{ left: '243.2578px' }}>not</span>
                                <span className="storyword" id="storywordB33" style={{ left: '279.1484px' }}>here</span>
                                <span className="storyword" id="storywordB34" style={{ left: '332.3437px' }}>with</span>
                            </div>
                            <div className="storyline">
                                <span className="storyword" id="storywordB35" style={{ left: '0px' }}>me</span>
                                <span className="storyword" id="storywordB36" style={{ left: '26.6094px' }}>anymore.</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='storyDiv'>
                    <div className={storyClassNameKarim} onMouseEnter={playKarim} onMouseLeave={pauseKarim}>
                        <div className='storyhead'>
                            <Link to={"/stories/details/3"} onClick={pauseKarim}>
                                <div className='name'>KARIM'S<br />TEARS</div>
                            </Link>
                            <div className='age'>Age 6</div>
                        </div>
                        <div className="storybody">
                            <div className="storyline">
                                <span className="storyword" id="storywordC1" style={{ left: '0px' }}>I’m</span>
                                <span className="storyword" id="storywordC2" style={{ left: '35.8906px' }}>Karim.</span>
                                <span className="storyword" id="storywordC3" style={{ left: '91.6718px' }}>Im</span>
                                <span className="storyword" id="storywordC4" style={{ left: '118.2812px' }}>6</span>
                                <span className="storyword" id="storywordC5" style={{ left: '135.58589px' }}>years</span>
                                <span className="storyword" id="storywordC6" style={{ left: '190.06249px' }}>old.</span>
                                <span className="storyword" id="storywordC7" style={{ left: '228.25779px' }}>My</span>
                                <span className="storyword" id="storywordC8" style={{ left: '253.86719px' }}>family</span>
                                <span className="storyword" id="storywordC9" style={{ left: '318.64839px' }}>was</span>
                            </div>
                            <div className="storyline">
                                <span className="storyword" id="storywordC10" style={{ left: '0px' }}>very</span>
                                <span className="storyword" id="storywordC11" style={{ left: '45.1953px' }}>big.</span>
                                <span className="storyword" id="storywordC12" style={{ left: '90.3906px' }}>My</span>
                                <span className="storyword" id="storywordC13" style={{ left: '117px' }}>house</span>
                                <span className="storyword" id="storywordC14" style={{ left: '171.4766px' }}>was</span>
                                <span className="storyword" id="storywordC15" style={{ left: '207.3672px' }}>always</span>
                                <span className="storyword" id="storywordC16" style={{ left: '271.1484px' }}>happy</span>
                                <span className="storyword" id="storywordC17" style={{ left: '325.625px' }}>with</span>
                            </div>
                            <div className="storyline">
                                <span className="storyword" id="storywordC18" style={{ left: '0px' }}>my</span>
                                <span className="storyword" id="storywordC19" style={{ left: '28.6094px' }}>brothers</span>
                                <span className="storyword" id="storywordC20" style={{ left: '108.3672px' }}>and</span>
                                <span className="storyword" id="storywordC21" style={{ left: '142.8906px' }}>sisters</span>
                                <span className="storyword" id="storywordC22" style={{ left: '217.0625px' }}>and</span>
                                <span className="storyword" id="storywordC23" style={{ left: '252.8906px' }}>my</span>
                                <span className="storyword" id="storywordC24" style={{ left: '281.6094px' }}>mom</span>
                                <span className="storyword" id="storywordC25" style={{ left: '317.8906px' }}>and</span>
                            </div>
                            <div className="storyline">
                                <span className="storyword" id="storywordC26" style={{ left: '0px' }}>dad.</span>
                                <span className="storyword" id="storywordC27" style={{ left: '45.1953px' }}>It</span>
                                <span className="storyword" id="storywordC28" style={{ left: '71.8047px' }}>is</span>
                                <span className="storyword" id="storywordC29" style={{ left: '98.4141px' }}>not</span>
                                <span className="storyword" id="storywordC30" style={{ left: '134.3047px' }}>the</span>
                                <span className="storyword" id="storywordC31" style={{ left: '170.1953px' }}>same</span>
                                <span className="storyword" id="storywordC32" style={{ left: '215.3906px' }}>anymore</span>
                                <span className="storyword" id="storywordC33" style={{ left: '288.4531px' }}>because</span>
                            </div>
                            <div className="storyline">
                                <span className="storyword" id="storywordC34" style={{ left: '0px' }}>I</span>
                                <span className="storyword" id="storywordC35" style={{ left: '17.30469px' }}>am</span>
                                <span className="storyword" id="storywordC36" style={{ left: '43.91409px' }}>alone</span>
                                <span className="storyword" id="storywordC37" style={{ left: '98.39069px' }}>now.</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='storyDiv'>
                    <div className={storyClassNameBilal} onMouseEnter={playBilal} onMouseLeave={pauseBilal}>
                        <div className='storyhead'>
                            <Link to={"/stories/details/4"} onClick={pauseBilal}>
                                <div className='name'>BILAL'S<br />TEARS</div>
                            </Link>
                            <div className='age'>Age 7</div>
                        </div>
                        <div className="storybody">
                            My mommy and daddy were always screaming
                            at each other. One day someone told me
                            that my parents were not taking care of
                            me anymore. I’m very scared that I will
                            never have a family again.
                        </div>
                    </div>
                </div>

                <div className='storyDiv'>
                    <div className={storyClassNameJamal} onMouseEnter={playJamal} onMouseLeave={pauseJamal}>
                        <div className='storyhead'>
                            <Link to={"/stories/details/5"} onClick={pauseJamal}>
                                <div className='name'>JAMAL’S<br />TEARS</div>
                            </Link>
                            <div className='age'>Age 5</div>
                        </div>
                        <div className='storybody'>
                            I miss my family and my friends and my
                            school. But I don’t think I will see my
                            friends again because my school and my
                            house are not there anymore. I hope that
                            someday I have my friends again.
                        </div>
                    </div>
                </div>

                <div className='storyDiv'>
                    <div className={storyClassNameAisha} onMouseEnter={playAisha} onMouseLeave={pauseAisha}>
                        <div className='storyhead'>
                            <Link to={"/stories/details/6"} onClick={pauseAisha}>
                                <div className='name'>AISHA’S<br />TEARS</div>
                            </Link>
                            <div className='age'>Age 9</div>
                        </div>
                        <div className='storybody'>
                            I am only 9 years old but I want someday
                            to be have the same happiness I had when
                            I was with my family. It was my home and
                            everybody loved me. It was a very
                            special home.
                        </div>
                    </div>
                </div>
            </div>

            <div className='storyButtons'>
                <Link className='back' to="/stories" style={{ opacity: '0' }}></Link>
                <Link className='more' to="/stories/details">more<br />stories</Link>
            </div>
        </div >
    );
}

export default Stories;