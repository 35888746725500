import { useEffect } from 'react';


export default function ErrorPage() {
  // const error = useRouteError();
  // console.error(error);

  return (
    <div className="error-page">
      <h1>Oops!</h1>
      <p>Sorry, the page you are looking for cannot be found.</p>
      <br/>
      <p>
        {/* <i>{error.statusText || error.message}</i> */}
      </p>
    </div>
  );
}