import React, { useState, useEffect, useRef, useContext } from 'react';
import LogoContext from "../context/LogoContext";
import { Link, useParams } from "react-router-dom";


function StoriesDetails() {

    let { id } = useParams();
    let { hash } = '#child' + id;
    const scrolledRef = React.useRef(false);
    const hashRef = React.useRef(hash);

    useEffect(() => {
        document.getElementById('logo').classList.remove('border')

        if (id != undefined) {
            hash = '#child' + id;
            if (hash) {
                // We want to reset if the hash has changed
                if (hashRef.current !== hash) {
                    hashRef.current = hash;
                    scrolledRef.current = false;
                }

                // only attempt to scroll if we haven't yet (this could have just reset above if hash changed)
                if (!scrolledRef.current) {
                    const id = hash.replace('#', '');
                    const element = document.getElementById(id);
                    if (element) {
                        element.scrollIntoView();
                        scrolledRef.current = true;
                    }
                }
            }
        }

    });

    const [imageToggleSaleem, setImageSaleem] = useState('bigTear')
    const [imageToggleAmina, setImageAmina] = useState('bigTear')
    const [imageToggleKarim, setImageKarim] = useState('bigTear')
    const [imageToggleBilal, setImageBilal] = useState('bigTear')
    const [imageToggleJamal, setImageJamal] = useState('bigTear')
    const [imageToggleAisha, setImageAisha] = useState('bigTear')
    const [imageToggleFatima, setImageFatima] = useState('bigTear')
    const [imageToggleKhaled, setImageKhaled] = useState('bigTear')

    const [imageToggle, setImage] = useState('imgs_tears')

    var enlargeTearSaleem = () => {
        setImage('imgs_tears show')
        setImageSaleem('bigTear show')
    }
    var enlargeTearAmina = () => {
        setImage('imgs_tears show')
        setImageAmina('bigTear show')
    }
    var enlargeTearKarim = () => {
        setImage('imgs_tears show')
        setImageKarim('bigTear show')
    }
    var enlargeTearBilal = () => {
        setImage('imgs_tears show')
        setImageBilal('bigTear show')
    }
    var enlargeTearJamal = () => {
        setImage('imgs_tears show')
        setImageJamal('bigTear show')
    }
    var enlargeTearAisha = () => {
        setImage('imgs_tears show')
        setImageAisha('bigTear show')
    }
    var enlargeTearFatima = () => {
        setImage('imgs_tears show')
        setImageFatima('bigTear show')
    }
    var enlargeTearKhaled = () => {
        setImage('imgs_tears show')
        setImageKhaled('bigTear show')
    }
    var minimizeTear = () => {
        setImage('imgs_tears')
        setImageSaleem('bigTear')
        setImageAmina('bigTear')
        setImageKarim('bigTear')
        setImageBilal('bigTear')
        setImageJamal('bigTear')
        setImageAisha('bigTear')
        setImageFatima('bigTear')
        setImageKhaled('bigTear')
    }

    return (
        <div className="storiesDetails">
            <div className='storyItem' id='child1'>
                <div className='storyHead'>
                    <div className='title'>Tears of longing</div>
                    <div className='collected'>Collected: 11/22</div>
                </div>
                <div className='storyBody'>
                    <div className='left'>
                        SALEEM'S<br />TEARS
                    </div>
                    <div className='center'>
                        <div className="text">
                            Eight years is too young an age to<br />
                            lose hope. But little Saleem has. <br /><br />
                            Once upon a time, he had a family, a <br />
                            home, and a homeland. Today, they <br />
                            exist.<br />
                            <span>only in his memory.</span><br />
                            A distant dream that seems too unreal <br />
                            to have even existed. He did not tell <br />
                            us all this. His tears did. <br /><br /><br />

                            <p>
                                Just like his personality, there is a <br />
                                sparkle in Saleem’s tears. At once <br />
                                shy and confident,<br />
                                <span>naughty and nice,</span><br />
                                it seems two worlds reside inside him <br />
                                all the time. One that feels at home <br />
                                anywhere,and another<br /></p>
                            <div className="lessLeft padleft">
                                <span>that longs for one. </span>
                            </div>

                            <br /><br /><br />
                            <p>
                                Photographed under a microscope, his <br />
                                tears reveal a distinct pattern <br />
                                classifying them as the <br />
                                <span className="noMove">tears</span> <br />
                                <span className="noMove">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;of</span><br />
                                <span className="noMove">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;longing</span><br />
                                Saleem is longing for someone to call <br />
                                his own, longing for friends, longing <br />
                                for a home.
                            </p>
                        </div>

                        <div className='viewTear' onClick={enlargeTearSaleem}>
                            <div className='btn'>TAP & VIEW HIS TEAR</div>
                            <img src="../../../tearBtn.png" />
                        </div>
                    </div>
                    <div className='right'>
                        AGE 8
                    </div>

                    <div className={imageToggle} >
                        <a className='close' onClick={minimizeTear}></a>
                        <img src="/saleemtearBig.jpg" className={imageToggleSaleem} />
                    </div>
                </div>
            </div>
            <div className='storyItem' id='child2'>
                <div className='storyHead'>
                    <div className='title'>Tears of Grief</div>
                    <div className='collected'>Collected: 11/22</div>
                </div>
                <div className='storyBody'>
                    <div className='left'>
                        Amena's<br />TEARS
                    </div>
                    <div className='center'>
                        <div className="text">

                            Amena is too young to even understand grief, let alone experience it. The only kid in her family, <br />
                            she is now the only one left in her family. Even when she laughs, her eyes don't.<br />
                            <div className='lineRelative' style={{ marginBottom: '200px' }}>She comes across as a carefree, energetic kid but her <span style={{ position: 'absolute', top: '20px' }}>tears</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;reveal that <span style={{ position: 'absolute', top: '15px' }}>inside</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;her <span style={{ position: 'absolute', top: '50px' }}>resides</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; a vast</div>
                            <div className='lineRelative' style={{ marginTop: '-200px', marginBottom: '150px' }}>landscape <span style={{ position: 'absolute', top: '30px' }}>of</span>&nbsp;&nbsp;&nbsp; <span style={{ position: 'absolute', top: '60px' }}>grief. The grief of not</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style={{ position: 'absolute', top: '90px' }}>having a</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span style={{ position: 'absolute', top: '120px' }}>home.</span></div>
                            <br />
                            The grief of not having someone to call her own. The grief of not belonging to a place. Grief that <br />
                            hides behind her playful nature. Grief that lingers behind her crooked smile. Grief that disguises <br />
                            itself as sudden silence.
                            <br /><br />
                            Grief that gets revealed in the photograph of her tears. Their pattern is so dense, it resembles a <br />
                            <div className='lineRelative' style={{ marginBottom: '200px' }}>quagmire that will <span style={{ position: 'absolute', top: '30px' }}>swallow</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;up all the <span style={{ position: 'absolute', top: '60px' }}>joy</span>&nbsp;&nbsp;&nbsp; in this world.</div>
                        </div>

                        <div className='viewTear' onClick={enlargeTearAmina} style={{ paddingLeft: '200px' }}>
                            <div className='btn'>TAP & VIEW HER TEAR</div>
                            <img src="../../../tearBtn.png" />
                        </div>
                    </div>
                    <div className='right'>
                        AGE 5
                    </div>

                    <div className={imageToggle} >
                        <a className='close' onClick={minimizeTear}></a>
                        <img src="/aminatearBig.jpg" className={imageToggleAmina} />
                    </div>
                </div>
            </div>
            <div className='storyItem' id='child3'>
                <div className='storyHead'>
                    <div className='title'>Tears of LONELINESS</div>
                    <div className='collected'>Collected: 11/22</div>
                </div>
                <div className='storyBody'>
                    <div className='left'>
                        Karim's<br />TEARS
                    </div>
                    <div className='center'>
                        <div className="text">
                            Karim loves to talk. One can see him<br />
                            speak the most in a group, chat<br />
                            endlessly with a friend, even discuss<br />
                            important issues like the weather<br />
                            with his <br />
                            <div className="lineRelative" style={{ marginLeft: '-50px', letterSpacing: '15px' }}>one-armed soft toy.</div>
                            Words just seem to pour out of his<br />
                            mouth and into the lives of those<br />
                            around him.<br /><br />

                            But the most important things he says<br />
                            <div className="lineRelative" style={{ marginBottom: '90px' }}>
                                a r e&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <span style={{ top: '20px', position: 'absolute' }}>the</span>
                                <span style={{ top: '40px', position: 'absolute', transform: 'translateX(-40px)' }}>ones</span>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <span style={{ top: '60px', position: 'absolute' }}>he does</span>
                                <span style={{ top: '80px', position: 'absolute', transform: 'translateX(-40px)' }}>not.</span>
                            </div>
                            How he would have loved talking to his<br />
                            mom and tell her what happened in<br />
                            class that day. Narrate to his dad in<br />
                            supreme detail how he missed scoring<br />
                            a goal by a whisker. Regale his<br />
                            siblings with his impromptu jokes.<br /><br />

                            They are not part of his world<br />
                            <div className='lineRelative' style={{ letterSpacing: '15px', marginLeft: '-40px' }}>anymore</div>
                            and he misses them every day. This<br />
                            tragic fact is spoken by his tears.<br />
                            Under a microscope they magnify<br />
                            Karim's l o n e l i n e s s,<br />
                            highlighting the solitude that was<br />
                            forced<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;upon<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;him<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;at such a young age.
                        </div>

                        <div className='viewTear' onClick={enlargeTearKarim} style={{ paddingLeft: '200px' }}>
                            <div className='btn'>TAP & VIEW HIS TEAR</div>
                            <img src="../../../tearBtn.png" />
                        </div>
                    </div>
                    <div className='right'>
                        AGE 5
                    </div>

                    <div className={imageToggle} >
                        <a className='close' onClick={minimizeTear}></a>
                        <img src="/karimtearBig.jpg" className={imageToggleKarim} />
                    </div>
                </div>
            </div>
            <div className='storyItem' id='child4'>
                <div className='storyHead'>
                    <div className='title'>Tears of FEAR</div>
                    <div className='collected'>Collected: 11/22</div>
                </div>
                <div className='storyBody'>
                    <div className='left'>
                        BILAL's<br />TEARS
                    </div>
                    <div className='center'>
                        <div className="text" style={{ marginTop: '100px', marginLeft: '-70px' }}>
                            Bilal is a brave kid.<br />
                            He is not afraid of the dark or the sound of thunder or the bark of an animal. But in a cruel twist of fate, he lives in constant fear. That<br />of never having a home that he could call his own.<br />
                            A conflict between the grownups of two countries led to this kid losing his home. Forever. Bilal is often quiet and does not speak much. One<br />needs to understand him through his silences.<br /><br />
                            There is the prolonged silence at night before bed, which means he is scared to sleep alone. There ist he silence in a group, which means he is scared to talk as he does not have any friends.<br />
                            There is the silence while having his meals, which means he is scared to eat with strangers.<br />
                            <div className='lineRelative'>But what his silence could not convey, his tears did. Photographed under a microscope, <span style={{ top: '30px', position: 'absolute' }}>his</span>&nbsp;&nbsp;&nbsp; tears revealed the <span style={{ top: '30px', position: 'absolute' }}>distinct</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; topography of a <span style={{ top: '100px', position: 'absolute' }}>tear</span></div>
                            <div className='lineRelative'>of fear.<span style={{ top: '30px', position: 'absolute' }}>Fear</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;that only a <span style={{ top: '30px', position: 'absolute' }}>loving</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; home can <span style={{ top: '70px', position: 'absolute' }}>drive</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;   out of Bilal's life.</div>
                        </div>

                        <div className='viewTear' onClick={enlargeTearBilal} style={{ paddingLeft: '200px' }}>
                            <div className='btn'>TAP & VIEW HIS TEAR</div>
                            <img src="../../../tearBtn.png" />
                        </div>
                    </div>
                    <div className='right'>
                        AGE 7
                    </div>

                    <div className={imageToggle} >
                        <a className='close' onClick={minimizeTear}></a>
                        <img src="/bilaltearBig.jpg" className={imageToggleBilal} />
                    </div>
                </div>
            </div>
            <div className='storyItem' id='child5'>
                <div className='storyHead'>
                    <div className='title'>Tears of SEPARATION</div>
                    <div className='collected'>Collected: 11/22</div>
                </div>
                <div className='storyBody'>
                    <div className='left'>
                        JAMAL'S<br />TEARS
                    </div>
                    <div className='center'>
                        <div className="text" style={{ marginTop: '100px' }}>
                            Jamal loved running. He ran from one<br />
                            room to another at home. He ran the<br />
                            length of his neighborhood every day.<br />
                            He ran at the annual school races. And<br />
                            he dreamt of running for his country<br />
                            one day<br />
                            <span style={{ letterSpacing: '15px', margin: '0' }}>till he had to flee it.</span><br /><br />
                            Jamal was separated from his family.<br />
                            His friends. His school. His home. His homeland. And his dream.<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;All of which is evident in his tear.<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A watery bubble that tells the tale of crushed ambitions, dashed hopes, and ruined memories.<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;What looks like an intricate work of<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;art from afar is the smashed pieces of<br />
                            <div className="lineRelative">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Jamal's life when <span style={{ position: 'absolute', top: '20px' }}>seen up</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span style={{ position: 'absolute', top: '90px' }}>close.</span></div>
                            <br /><br /><br /><br />
                        </div>

                        <div className='viewTear' onClick={enlargeTearJamal} style={{ paddingLeft: '200px' }}>
                            <div className='btn'>TAP & VIEW HIS TEAR</div>
                            <img src="../../../tearBtn.png" />
                        </div>
                    </div>
                    <div className='right'>
                        AGE 5
                    </div>

                    <div className={imageToggle} >
                        <a className='close' onClick={minimizeTear}></a>
                        <img src="/jamaltearBig.jpg" className={imageToggleJamal} />
                    </div>
                </div>
            </div>
            <div className='storyItem' id='child6'>
                <div className='storyHead'>
                    <div className='title'>Tears of loss</div>
                    <div className='collected'>Collected: 11/22</div>
                </div>
                <div className='storyBody'>
                    <div className='left'>
                        AISHA's<br />TEARS
                    </div>
                    <div className='center'>
                        <div className="text">
                            Every day is the same for<br />
                            nine-year-old Aisha. A handful of<br />
                            fleeting moments of joy engulfed by a<br />
                            looming sense of loss. The loss of<br />
                            happy memories that could have been.<br />
                            The loss of a place that was home. The<br />
                            loss of family. The loss of friends<br />
                            who were family. But the biggest loss<br />
                            has been the loss of her childhood.<br /><br />
                            She had to grow up years in a matter<br />
                            of only a few days. She went from<br />
                            being a child who was dependent on her<br />
                            parents to a child who was forced to<br />
                            become independent almost overnight.<br />
                            On days when she feels like talking,<br />
                            she reminisces about her home. The<br /><br />
                            lanes where she played with her<br /><br />
                            friends that felt like a second<br /><br /><br />
                            home. The home of her grandmother.<br /><br /><br />
                            The home she grew up in and the home<br /><br /><br /><br />
                            where her parents were supposed to<br /><br /><br /><br /><br /><br />
                            grow old in. All the homes that<br /><br /><br /><br /><br /><br /><br /><br />
                            don't exist anymore.
                        </div>

                        <div className='viewTear' onClick={enlargeTearAisha} style={{ paddingLeft: '200px' }}>
                            <div className='btn'>TAP & VIEW HER TEAR</div>
                            <img src="../../../tearBtn.png" />
                        </div>
                    </div>
                    <div className='right'>
                        AGE 9
                    </div>

                    <div className={imageToggle} >
                        <a className='close' onClick={minimizeTear}></a>
                        <img src="/aishatearBig.jpg" className={imageToggleAisha} />
                    </div>
                </div>
            </div>
            <div className='storyItem' id='child7'>
                <div className='storyHead'>
                    <div className='title'>TEARS OF EMPTINESS</div>
                    <div className='collected'>Collected: 11/22</div>
                </div>
                <div className='storyBody'>
                    <div className='left'>
                        FATIMA's<br />TEARS
                    </div>
                    <div className='center'>
                        <div className="text" style={{ marginTop: '-220px' }}>
                            Four-year-old Fatima may seem full of<br />
                            an empty existence on the inside. <br />
                            From a house full of people, a home<br />
                            full love, Fatima was cruelly thrust <br />
                            <div className='lineRelative' style={{ marginBottom: '30px' }}>into a <span style={{ position: 'absolute', top: '30px' }}>life</span>&nbsp;&nbsp;&nbsp;&nbsp; of desolation by <span style={{ position: 'absolute', top: '60px' }}>fate.</span></div><br /><br />

                            She lives among other kids who share <br />
                            a similar history. She borrows their <br />
                            emptiness, shares hers, unwittingly <br />
                            <div className='lineRelative' style={{ marginBottom: '30px' }}>hoping to fill <span style={{ position: 'absolute', top: '30px' }}>their life and</span> </div><br />
                            <div className='lineRelative' style={{ marginBottom: '60px' }}>hers with a <span style={{ position: 'absolute', top: '30px' }}>little bit</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style={{ position: 'absolute', top: '60px' }}>of</span> &nbsp;&nbsp;&nbsp;<span style={{ position: 'absolute', top: '90px' }}>hope.</span></div><br /><br />

                            At an age when children cry for toys<br />
                            and ask for their favorite food, all <br />
                            that Fatima wants is the company<br />
                            <div className='lineRelative' style={{ marginBottom: '90px' }}>and <span style={{ position: 'absolute', top: '30px' }}>embrace</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span style={{ position: 'absolute', top: '60px' }}>of</span>&nbsp;&nbsp; <span style={{ position: 'absolute', top: '90px' }}>her</span>&nbsp;&nbsp;&nbsp; <span style={{ position: 'absolute', top: '30px' }}>parents</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; who are </div><br />
                            sadly not alive. Her tears reflect<br />
                            the emptiness that has been left <br />
                            <div className='lineRelative' style={{ marginBottom: '90px' }}>behind <span style={{ position: 'absolute', top: '30px' }}>by</span> &nbsp;&nbsp;&nbsp;<span style={{ position: 'absolute', top: '90px' }}>the</span>&nbsp;&nbsp;&nbsp; <span style={{ position: 'absolute', top: '60px' }}>loss</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; of her parents. </div><br /><br />

                            But what her tears don't tell you is<br />
                            how strong her little heart is. How <br />
                            courageous she  is  to  have accepted <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;what  <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;has<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;happened.<br /><br />

                            Fatima is a compassionate and <br />
                            intelligent soul residing in the body <br />
                            <div className='lineRelative' style={{ marginBottom: '30px' }}>of a <span style={{ position: 'absolute', top: '30px' }}>little</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style={{ position: 'absolute', top: '90px' }}>girl.</span></div>
                        </div>

                        <div className='viewTear' onClick={enlargeTearFatima} style={{ marginLeft: '-400px' }}>
                            <div className='btn'>TAP & VIEW HER TEAR</div>
                            <img src="../../../tearBtn.png" />
                        </div>
                    </div>
                    <div className='right'>
                        AGE 4
                    </div>

                    <div className={imageToggle} >
                        <a className='close' onClick={minimizeTear}></a>
                        <img src="/fatimatearBig.jpg" className={imageToggleFatima} />
                    </div>
                </div>
            </div>
            <div className='storyItem' id='child8'>
                <div className='storyHead'>
                    <div className='title'>TEARS OF HELPLESSNESS</div>
                    <div className='collected'>Collected: 11/22</div>
                </div>
                <div className='storyBody'>
                    <div className='left'>
                        KHALED'S<br />TEARS
                    </div>
                    <div className='center'>
                        <div className="text">
                            <div className='flexLine'><span>A</span>
                                <span>two-year-old</span>
                                <span>cannot</span>
                                <span>say</span>
                                <span>much,</span>
                                <span>but</span></div>
                            <div className='flexLine'><span>his</span>
                                <span>tears</span>
                                <span>can.</span>
                                <span>Khaled's</span>
                                <span>story</span>
                                <span>was</span>
                                <span>not</span></div>
                            <div className='flexLine'><span>a</span>
                                <span>unique</span>
                                <span>one,</span>
                                <span>he</span>
                                <span>was</span>
                                <span>just</span>
                                <span>one</span>
                                <span>among</span></div>
                            <div className='flexLine'><span>the</span>
                                <span>many children</span>
                                <span>who</span>
                                <span>were</span></div>
                            <div className='flexLine'><span>torn</span>
                                <span>away</span>
                                <span>from</span>
                                <span>their</span>
                                <span>families</span>
                                <span>and</span></div>
                            <div className='flexLine'><span>homes.</span>
                                <span>Growing</span>
                                <span>up</span>
                                <span>without</span>
                                <span>a</span></div>
                            <div className='flexLine'>
                                <span>family,</span>
                                <span>without</span>
                                <span>knowing</span>
                                <span>where</span>
                                <span>he</span>
                                <span>came</span></div>
                            <div className='flexLine'><span>from</span>
                                <span>or</span>
                                <span>who</span>
                                <span>his</span>
                                <span>family</span></div>
                            <div className='flexLine'>
                                <span>was,</span>
                                <span>is</span>
                                <span>a</span>
                                <span>burden</span>
                                <span>that</span>
                                <span>no</span></div>
                            <div className='flexLine'><span>child</span>
                                <span>should</span>
                                <span>have</span>
                                <span>to</span></div>
                            <div className='flexLine'><span>bear.</span>
                                <span>The</span>
                                <span>thought</span>
                                <span>of</span>
                                <span>Khaled,</span>
                                <span>and</span></div>
                            <div className='flexLine'><span>countless</span>
                                <span>others</span>
                                <span>like</span>
                                <span>him,</span></div>
                            <div className='flexLine'><span>growing</span>
                                <span>up</span>
                                <span>without</span>
                                <span>the</span>
                                <span>love</span></div>
                            <div className='flexLine'>
                                <span>and</span>
                                <span>care</span>
                                <span>of</span>
                                <span>a</span>
                                <span>mother</span>
                            </div>

                            <div className='flexLine'>
                                <span>and</span>
                                <span>father,</span>
                                <span>was</span>
                                <span>enough</span>
                                <span>to</span>
                            </div>

                            <div className='flexLine'>
                                <span>break</span>
                                <span>the</span>
                                <span>hardest</span>
                                <span>of</span>
                            </div>

                            <div className='flexLine'>
                                <span>hearts.</span>
                                <span>The</span>
                                <span>microscopic</span>
                            </div>

                            <div className='flexLine' style={{ justifyContent: 'flex-end' }}>
                                <span>images</span>&nbsp;&nbsp;&nbsp;&nbsp;
                                <span>captured</span>&nbsp;&nbsp;&nbsp;&nbsp;
                                <span>the</span>
                            </div>

                            <div className='flexLine'>


                                <span>essence</span>
                                <span>of</span>
                                <span>his</span>
                            </div>

                            <div className='flexLine' style={{ justifyContent: 'flex-end' }}>
                                <span>tears.</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <span>Showing</span>
                            </div>

                            <div className='flexLine'>


                                <span>through</span>
                                <span>them</span>
                                <span>the</span>
                            </div>

                            <div className='flexLine' style={{ justifyContent: 'flex-end' }}>
                                <span>effects</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <span>that</span>
                            </div>

                            <div className='flexLine' style={{ justifyContent: 'flex-end' }}>
                                <span>abandonment</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <span>has</span>&nbsp;&nbsp;&nbsp;&nbsp;
                                <span>on</span>
                            </div>

                            <div className='flexLine' style={{ justifyContent: 'flex-end' }}>
                                <span>the</span>&nbsp;&nbsp;
                                <span>innocent</span>&nbsp;&nbsp;
                                <span>and</span>
                            </div>

                            <div className='flexLine'>
                                <span>defenseless.</span>
                                <span>Khaled's</span>
                                <span>tear</span>
                            </div>

                            <div className='flexLine' style={{ justifyContent: 'flex-end' }}>
                                <span>is</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <span>one</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <span>of</span>
                            </div>

                            <div className='flexLine' style={{ justifyContent: 'flex-end' }}>
                                <span>helplessness</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <span>as</span>
                            </div>

                            <div className='flexLine' style={{ justifyContent: 'flex-end' }}>
                                <span>photographed</span>
                            </div>

                            <div className='flexLine' style={{ justifyContent: 'flex-start' }}>
                                <span>under</span>&nbsp;&nbsp;
                                <span>a</span>&nbsp;&nbsp;
                                <span>microscope.</span>
                            </div>

                            <div className='flexLine' style={{ justifyContent: 'flex-end' }}>
                                <span>There</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <span>are</span>
                            </div>

                            <div className='flexLine' style={{ justifyContent: 'flex-end' }}>
                                <span>many</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <span>more</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <span>like</span>
                            </div>

                            <div className='flexLine' style={{ justifyContent: 'flex-end' }}>
                                <span>him</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <span>who</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </div>

                            <div className='flexLine' style={{ justifyContent: 'flex-end' }}>
                                <span>might</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <span>grow</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </div>

                            <div className='flexLine' style={{ justifyContent: 'flex-end' }}>
                                <span>never</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <span>knowing</span>
                            </div>

                            <div className='flexLine' style={{ justifyContent: 'flex-end' }}>
                                <span>what</span>&nbsp;&nbsp;&nbsp;
                                <span>a</span>
                            </div><br />

                            <div className='flexLine' style={{ justifyContent: 'flex-end' }}>
                                <span>home</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </div><br /><br />

                            <div className='flexLine' style={{ justifyContent: 'flex-end' }}>
                                <span>feels</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </div><br /><br />

                            <div className='flexLine' style={{ justifyContent: 'flex-end' }}>
                                <span>like.</span>
                            </div>
                        </div>

                        <div className='viewTear' onClick={enlargeTearKhaled} style={{ marginLeft: '-400px' }}>
                            <div className='btn'>TAP & VIEW HIS TEAR</div>
                            <img src="../../../tearBtn.png" />
                        </div>
                    </div>
                    <div className='right'>
                        AGE 2
                    </div>

                    <div className={imageToggle} >
                        <a className='close' onClick={minimizeTear}></a>
                        <img src="/khaledtearBig.jpg" className={imageToggleKhaled} />
                    </div>
                </div>
            </div>
            <div className='storyButtons'>
                <Link className='back' to="/stories">back to<br />stories</Link>
            </div>
        </div>
    );
}

export default StoriesDetails;