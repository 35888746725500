import React, { createRef, useState, useEffect, useContext } from 'react';
import Slider from 'react-rangeslider'
import { useScreenshot, createFileName } from "use-react-screenshot";
import LogoContext from "../context/LogoContext";
import 'react-rangeslider/lib/index.css';
import { FacebookShareButton } from 'react-share';
import { useNavigate } from "react-router-dom";


function LogoGenerator() {

    const navigate = useNavigate();

    const logoCtx = useContext(LogoContext);

    useEffect(() => {
        fetchLettersArray();
        hideLogo();
    });

    const [textDropped, setTextDropped] = useState(false)
    const [logoCreated, setLogoCreated] = useState(false)
    const [willDoIt, setwillDoIt] = useState(false)
    const [volume, setVolume] = useState(0)

    const ref = createRef(null);
    const [width, setWidth] = useState(1000);
    const [apiResponse, setApiResponse] = useState("");
    const [image, takeScreenShot] = useScreenshot({
        type: "image/jpeg",
        quality: 12.0
    });

    // const [showItems, setShowItems] = useState({
    //     show1:false,
    //     show2:false
    // })

    // setShowItems({...prevState,show2:true});

    let listItemsALL = [];

    const hideLogo = () => {
        document.getElementById('logo').classList.add('border');
    }

    const fetchLettersArray = () => {
        const lettersContainer1 = document.getElementById('lettersSet1');
        const lettersContainer2 = document.getElementById('lettersSet2');
        const listItems1 = lettersContainer1.getElementsByTagName("li");
        const listItems2 = lettersContainer2.getElementsByTagName("li");

        for (let i = 0; i <= listItems1.length - 1; i++) {
            listItemsALL[i] = listItems1[i]
        }

        for (let j = listItems1.length; j <= (listItems2.length + listItems1.length) - 1; j++) {
            listItemsALL[j] = listItems2[j - listItems1.length];
        }
        // console.log(listItemsALL)
    }

    const handleOnChange = (value) => {
        setVolume(value)

        document.getElementById('letter3').style.marginBottom = value * 0.1 + 'px';
        document.getElementById('letter4').style.marginBottom = value * 0.2 + 'px';
        document.getElementById('letter5').style.marginBottom = value * 0.6 + 'px';
        document.getElementById('letter6').style.marginBottom = value * 0.8 + 'px';

        document.getElementById('letter8').style.marginBottom = value * 0.5 + 'px';
        document.getElementById('letter9').style.marginBottom = value * 0.7 + 'px';
        document.getElementById('letter10').style.marginBottom = value * 0.9 + 'px';
    }

    const tear = (e) => {
        e.preventDefault();
        if (willDoIt) {
            e.target.parentElement.classList.add('clicked');
        }
    }

    const dropLogoLetters = () => {
        setTextDropped(true);

        shuffleArray(listItemsALL);

        for (let k = 0; k <= listItemsALL.length - 1; k++) {
            setTimeout(function () {
                document.getElementById(listItemsALL[k].id).classList.add('show')
            }, getRandomInt(0, 3000));
        }

    }

    function shuffleArray(array) {
        let currentIndex = array.length, randomIndex;

        // While there remain elements to shuffle.
        while (currentIndex !== 0) {

            // Pick a remaining element.
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;

            // And swap it with the current element.
            [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
        }

        return array;
    }

    function getRandomInt(min, max) {
        return min + Math.floor(Math.random() * (max - min));
        // return Math.floor(Math.random() * max);
    }

    function clearTears() {
        for (let i = 0; i < listItemsALL.length; i++) {
            if (document.getElementById(listItemsALL[i].id).classList.contains('clicked')) {
                document.getElementById(listItemsALL[i].id).classList.remove('clicked')
            }
        }
    }

    function surpriseMe() {
        setLogoCreated(true);
        clearTears();

        const r = getRandomInt(3, 7);

        shuffleArray(listItemsALL)
        // console.log(r)

        for (let i = 0; i < r; i++) {
            setTimeout(function () {
                document.getElementById(listItemsALL[i].id).classList.add('clicked')
            }, getRandomInt(0, 500));
        }
    }

    function iWillDoIt() {
        setLogoCreated(true);
        setwillDoIt(true);
    }

    const takeScreenshot = () => {
        takeScreenShot(ref.current).then(saveImgToLocalStorage);
    }

    const saveImgToLocalStorage = (image = {}) => {
        logoCtx.setLogo(image)
        logoCtx.setLogoClass('logo customized')

        callAPI(image)

        setTimeout(function () {
            document.getElementById('logo').classList.remove('border');
            navigate("/stories");
        }, 750);
    };


    const imageAPI_URL = "http://localhost:4000/imageApi"

    const callAPI = (image) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "base64": image
            })
        };
        fetch(imageAPI_URL, requestOptions)
            .then(res => res.json())
            .then(result => {
                const response = result.fileName;
                logoCtx.setLogo(response)
                document.getElementById("ogImg").setAttribute("content", response);
            });
    }


    return (
        <div className="logoGenerator">
            <div className="content">
                {/* <div className="title">
                    BE A PART OF THE STORY
                </div> */}
                <div className="configurator">
                    <div className="row">
                        <div className="col-md-9 preview">
                            <div className='letters' ref={ref}>
                                <ul id="lettersSet1">

                                    {/* {listItemsALL.map((item, index) => (
                                        <li id={`letter${index + 1}`} onClick={tear} className={`${show[index] ? "show" : ""}`}>
                                            <img src="letters/letter1.png" />
                                            <img className='tear' src="letters/letter1_tear.png" />
                                        </li>
                                    ))} */}
                                    <li id="letter1" onClick={tear}>
                                        <img src="renderedletters/letter1.png" alt="letter" />
                                        <img className='tear' src="renderedletters/letter1_tear.png" alt="letter" />
                                    </li>
                                    <li id="letter2" onClick={tear}>
                                        <img src="renderedletters/letter2.png" alt="letter" />
                                        <img className='tear' src="renderedletters/letter2_tear.png" alt="letter" />
                                    </li>
                                    <li id="letter3" onClick={tear}>
                                        <img src="renderedletters/letter3.png" alt="letter" />
                                        <img className='tear' src="renderedletters/letter3_tear.png" alt="letter" />
                                    </li>
                                    <li id="letter4" onClick={tear}>
                                        <img src="renderedletters/letter4.png" alt="letter" />
                                        <img className='tear' src="renderedletters/letter4_tear.png" alt="letter" />
                                    </li>
                                    <li id="letter5" onClick={tear}>
                                        <img src="renderedletters/letter5.png" alt="letter" />
                                        <img className='tear' src="renderedletters/letter5_tear.png" alt="letter" />
                                    </li>
                                    <li id="letter6" onClick={tear}>
                                        <img src="renderedletters/letter6.png" alt="letter" />
                                        <img className='tear' src="renderedletters/letter6_tear.png" alt="letter" />
                                    </li>
                                </ul>
                                <ul id="lettersSet2">
                                    <li id="letter7" onClick={tear}>
                                        <img src="renderedletters/letter7.png" alt="letter" />
                                        <img className='tear' src="renderedletters/letter7_tear.png" alt="letter" />
                                    </li>
                                    <li id="letter8" onClick={tear}>
                                        <img src="renderedletters/letter8.png" alt="letter" />
                                        <img className='tear' src="renderedletters/letter8_tear.png" alt="letter" />
                                    </li>
                                    <li id="letter9" onClick={tear}>
                                        <img src="renderedletters/letter9.png" alt="letter" />
                                        <img className='tear' src="renderedletters/letter9_tear.png" alt="letter" />
                                    </li>
                                    <li id="letter10" onClick={tear}>
                                        <img src="renderedletters/letter10.png" alt="letter" />
                                        <img className='tear' src="renderedletters/letter10_tear.png" alt="letter" />
                                    </li>
                                    <li id="letter11" onClick={tear}>
                                        <img src="renderedletters/letter11.png" alt="letter" />
                                        <img className='tear' src="renderedletters/letter11_tear.png" alt="letter" />
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-3 tuningKnobs">
                            <ul>
                                <li>
                                    <div className="title">EXPERIENCE<br />THE IMPACT<br />OF A TEAR</div>
                                </li>
                                <li>
                                    <div className="title">
                                        Reveal
                                    </div>
                                    <div className="controls">
                                        <button onClick={dropLogoLetters}>Drop text</button>
                                        <span>Set Distance</span>

                                        <Slider
                                            className={`${textDropped ? "enabled" : "disabled"}`}
                                            value={volume}
                                            onChange={handleOnChange}
                                        />

                                        {/* <Slider min="0" max="100" step="1" value="0" onChange="logValue()" /> */}
                                        {/* // orientation={String}
                                            // reverse={Boolean}
                                            // tooltip={Boolean}
                                            // labels={Object}
                                            // handleLabel={String}
                                            // format={Function}
                                            // onChangeStart={Function}
                                            // onChangeComplete={Function} */}
                                    </div>
                                </li>
                                <li>
                                    <div className="title">
                                        TEAR DROPS
                                    </div>
                                    <div className="controls">
                                        <button onClick={surpriseMe} disabled={!textDropped}>SURPRISE ME</button>
                                        <button onClick={iWillDoIt} disabled={!textDropped}>I’LL DO IT</button>
                                    </div>
                                </li>
                                <li>
                                    <div className="title">
                                        EXPORT
                                    </div>
                                    <div className="controls">
                                        <FacebookShareButton disabled={!logoCreated}
                                            url={'https://unseentears.thehomecoming.me/'}
                                            quote={'See tears in a way you have never seen them before.'}
                                            hashtag=""
                                        >
                                            Share
                                        </FacebookShareButton>

                                        <button disabled={!logoCreated} onClick={takeScreenshot}>save</button>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className='homecenterLogo'>
                <img src="homeCenter.svg" alt="home center"/>
            </div>
        </div>
    );
}

export default LogoGenerator;
