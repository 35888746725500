import React, { useState, useEffect, useRef, useContext } from 'react';
import LogoContext from "../context/LogoContext";


function Sidebar() {

    let logoCtx = useContext(LogoContext);

    return (
        <div className="sidebar">
            <div className={logoCtx.logoClass} id="logo">
                <a href="/">
                    <img src={logoCtx.logo} alt='logo' />
                </a>
            </div>

            <div className="collected">
                COLLECTED<br />01&nbsp;&nbsp;/&nbsp;&nbsp;22
            </div>
        </div>
    );
}

export default Sidebar;