import { NavLink } from "react-router-dom";

function Header() {
    return (
        <header className="headercomponent">
            <ul>
                <li><NavLink to="/stories" >stories</NavLink ></li>
                <li><NavLink to="/sessions">SESSIONS</NavLink ></li>
                <li><NavLink to="/book">THE BOOK</NavLink ></li>
                <li><a href="https://www.thehomecoming.me/" target="_blank" >THE HOMECOMING</a></li>
            </ul>
            <div className='icon'>
                <img src="/icon.svg" alt='icon' />
            </div>
        </header>
    );
}

export default Header;
